<template>
    <div aria-live="polite" aria-atomic="true" class="position-relative">
        <div class="toast-container position-absolute top-0 end-0 p-3">
            <div class="alert alert-dismissible fade show alert-flash"
                 role="alert"
                 :class="classes"
                 v-show="show"
                 v-text="body">

                <button type="button" class="btn-close float-end" aria-label="Close"></button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['message'],

        data() {
            return {
                body: this.message,
                level: 'success',
                show: false
            }
        },

        computed: {
            classes() {
                let defaults = ['text-center'];

                if (this.level === 'success') defaults.push('alert-success');
                if (this.level === 'warning') defaults.push('alert-warning');
                if (this.level === 'danger') defaults.push('alert-danger');

                return defaults;
            }
        },

        created() {
            if (this.message) {
                this.flash();
            }

            window.events.$on(
                'flash', data => this.flash(data)
            );
        },

        methods: {
            flash(data) {
                if (data) {
                    this.body = data.message;
                    this.level = data.level;
                }

                this.show = true;

                this.hide();
            },

            hide() {
                setTimeout(() => {
                    this.show = false;
                }, 5000);
            }
        }
    };
</script>


<style>
    .alert-flash {
        position: fixed;
        right: 7px;
        top: 7px;
        z-index: 9999;
    }
</style>
